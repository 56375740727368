import { useState } from "react";

import { Tabs, Arrange, Drawer, Typography, Spacer } from "@aviary";
import { Direction } from "@aviary/types";
import { useEventListener, useKeyCombo, useKeyPressed } from "@shared/hooks";
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { StopImpersonating } from "../StopImpersonating";

import { ABTests } from "./ABTests";
import { FlipperToast } from "./FlipperToast";
import { Flippers } from "./Flippers";
import { Pat } from "./Pat";
import { Scrollable } from "./Scrollable";

import * as styles from "./ExperimentTester.styles";

const ExperimentTester = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTabId, setCurrentTabId] = useState("A/B Tests");

  useEventListener({
    eventName: "toggle-experiment-tester",
    listener: () => toggle(),
  });

  useKeyCombo({
    targetKeys: ["e"],
    withShift: false,
    onPressed: () => {
      setCurrentTabId("A/B Tests");
      toggle();
    },
  });

  useKeyCombo({
    targetKeys: ["e"],
    withShift: true,
    onPressed: () => {
      setCurrentTabId("Flippers");
      toggle();
    },
  });

  useKeyPressed("Escape", () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(prev => !prev);
  };

  const renderFlippersTab = () => {
    if (isProduction()) return;

    return <Tabs.Header tabId="Flippers">Flippers</Tabs.Header>;
  };

  const renderFlippersContent = () => {
    if (isProduction()) return;

    return (
      <Tabs.Content tabId="Flippers">
        <Flippers onCloseDrawer={() => setIsOpen(false)} />
      </Tabs.Content>
    );
  };

  return (
    <>
      <FlipperToast />
      <Drawer
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        hasBackdrop
        opensFrom={Direction.LEFT}
        isMaxIndex
        css={styles.tester}
        data-testid="experiment-tester"
      >
        <StopImpersonating css={styles.impersonateButton} />
        <div css={styles.all}>
          <Scrollable>
            <Arrange isVertical alignment="center" css={styles.content}>
              <Pat />
              <Typography type="h1">Pat's Experiment Tester</Typography>
            </Arrange>
            <Spacer height="one" />
            <Tabs initialTabId={currentTabId}>
              <Tabs.Headers customHeadersStyle={styles.tabHeaders}>
                <Tabs.Header tabId="A/B Tests">A/B Tests</Tabs.Header>
                {renderFlippersTab()}
              </Tabs.Headers>
              <Tabs.Content tabId="A/B Tests">
                <ABTests />
              </Tabs.Content>
              {renderFlippersContent()}
            </Tabs>
          </Scrollable>
        </div>
      </Drawer>
    </>
  );
};

export { ExperimentTester };
export default ExperimentTester;
